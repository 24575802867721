<!-- eslint-disable -->
<template>

  <svg xmlns:xlink="http://www.w3.org/1999/xlink"
class="vuestic-icon-faster"
viewBox="0 0 24 24"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg">
    <!-- Generator: sketchtool 48.2 (47327) - http://www.bohemiancoding.com/sketch -->
    <title>62EBC3B8-A55C-4B01-95A2-52FB8EDD4150</title>
    <defs></defs>
    <g id="symbols"
stroke="none"
stroke-width="1"
fill="none"
       fill-rule="evenodd">
      <g id="icon-faster" fill="#34495E">
        <g>
          <path
d="M17.748,19 L16.956,16.3 L12.942,16.3 L12.168,19 L8.928,19 L13.302,6.13 L16.614,6.13 L20.988,19 L17.748,19 Z M14.976,9.064 L14.94,9.064 C14.94,9.064 14.652,10.468 14.418,11.278 L13.68,13.78 L16.218,13.78 L15.498,11.278 C15.264,10.468 14.976,9.064 14.976,9.064 Z"
            id="A"></path>
          <rect id="Rectangle-4"
x="3"
y="11"
width="5"
height="2"
                rx="1"></rect>
          <rect id="Rectangle-4-Copy"
x="4"
y="7"
width="6"
height="2"
                rx="1"></rect>
          <rect id="Rectangle-4"
x="2"
y="15"
width="4"
height="2"
                rx="1"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'vuestic-icon-faster',
};
</script>

<style lang="scss">
.vuestic-icon-faster {
  width: 24px;
  height: 24px;
}
</style>
